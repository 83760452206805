
import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').click(function () {
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
    && location.hostname == this.hostname) {
    let $target = $(this.hash);
    $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
    let offsetTop = $(".c-header").height();
    if ($target.length) {
      let targetOffset = $target.offset().top - offsetTop;
      $('html,body').animate({ scrollTop: targetOffset }, 1000);
      return false;
    }
  }
});

let target = window.location.hash;
target = target.replace('#', '');
if (target.length) {
  $('html, body').animate(
    { scrollTop: $('#' + target).offset().top - $('.c-header').outerHeight() }, 2000);
}
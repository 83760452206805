import $ from 'jquery';
import Anchor from '../Components/_Anchor.js';
import Wow from '../Components/_Wow.js';

let scrl_pos;
let bodyHtml = $('body, html');
let body = $('body');

$('.js-menu').on('click', function () {
  $(this).toggleClass('is-active');
  $('.c-header__iconmenu').toggleClass('is-active');
  $('.c-header__nav1').toggleClass('is-active');
  $('.c-header__nav2').toggleClass('is-active');

  if ($(window).outerWidth() < 768) {
    if ($(this).hasClass('is-active')) {
      scrl_pos = $(window).scrollTop();
      body.addClass('is-fixed').css({ top: -scrl_pos });
    } else {
      body.removeClass('is-fixed').removeAttr('style');
      bodyHtml.animate({ scrollTop: scrl_pos }, 0);
    }
  }
});

$(window).on('scroll', function () {
  if ($(window).outerWidth() > 767) {
    $('.c-header').css('left', -$(window).scrollLeft());
  } else {
    $('.c-header').css('left', 0);
  }
});

$(function () {
  let elem = $('#js-gotop .c-gotop__anchor');
  if (!elem.length) return;
  let tmp = elem
    .clone()
    .attr('class', 'c-gotop__anchor is-tmp')
    .css('visibility', 'hidden');
  let parent = elem.parent();
  document.addEventListener('scroll', function () {
    let scrollTop = $(this).scrollTop();
    let parentTop = parent.offset().top;
    let parentHeight = parent.outerHeight();
    let bottom = 20;
    if ($(window).width() <= 767) {
      bottom = 20;
    }
    if (scrollTop > 150) {
      parent.append(tmp);
      elem.addClass('is-show').css({ position: 'fixed', bottom: bottom });
      if (
        scrollTop >=
        parentTop - window.innerHeight + (parentHeight + bottom)
      ) {
        tmp.css({ visibility: '' });
        elem.css({ visibility: 'hidden' });
      } else {
        tmp.css({ visibility: 'hidden' });
        elem.css({ visibility: '' });
      }
    } else {
      parent.find('.is-tmp').remove();
      elem.removeClass('is-show');
    }
  });
});

$(function () {
  $(document).on('click', '#js-gotop .c-gotop__anchor', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 800);
  });
});

/*------------------------------------------------------------
js-toggle
------------------------------------------------------------*/
$('.js-toggle').on('click', function (e) {
  e.preventDefault();
  let parent = $(this).parent();
  parent.find('.js-toggle__content').slideToggle();
  if (parent.hasClass('is-active')) {
    parent.removeClass('is-active');
  } else {
    parent.addClass('is-active');
  }
});

let windowWidth = window.outerWidth;
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', function () {
  if (window.outerWidth != windowWidth) {
    appHeight();
  }
});
appHeight();

// form
$('input[type=checkbox]').parent().addClass('c-form1__checkprivacy');
